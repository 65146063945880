
import Vue from 'vue'
import * as SiteConfig from '~/app/siteConfig.js';
import {defineComponent} from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'SearchSchema',
  data() {
    const searchJsonLd = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": SiteConfig.siteBaseUrl,
      "potentialAction": [{
        "@type": "SearchAction",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": SiteConfig.siteBaseUrl + "/search?s={term}"
        },
        "query-input": "required name=term"
      }]
    }

    return {
      searchJsonLd
    }
  }

});
